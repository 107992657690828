<template>
  <v-carousel
    class="mb-8 main-slider"
    cycle
    :hide-delimiters="true"
    :show-arrows="false"
    :height="height"
  >
    <template v-for="(slide, i) in slides">
      <v-carousel-item :key="i">
        <a
          v-if="slide.page_url && slide.page_url.startsWith('http')"
          :href="slide.page_url"
          class="external-link"
        >
          <v-img
            :src="slide.photo_mobile || slide.photo"
            :aspect-ratio="800 / 600"
          />
        </a>

        <v-img
          v-else
          :src="slide.photo_mobile || slide.photo"
          :aspect-ratio="800 / 600"
        />
      </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MainSlider',

  data: () => ({
    height: 'auto',
  }),

  computed: {
    ...mapState('slider', ['slides']),
  },

  async created() {
    await this.$store.dispatch('slider/loadSlides')
  },
}
</script>

<style lang="scss" scoped>
.main-slider {
  border-radius: $border-radius-root;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
</style>
